import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './global.css';
import { RemoveScroll } from 'react-remove-scroll';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';

const HomeScreen = lazy(() => import('./Screens/HomeScreen'));
const MapScreen = lazy(() => import('./Screens/MapScreen'));
const ProfileScreen = lazy(() => import('./Screens/ProfileScreen'));
const BusinessScreen = lazy(() => import('./Screens/PlaceProfileScreen'));
const AuthScreen = lazy(() => import('./Screens/AuthScreen'));
const NavScreen = lazy(() => import('./Screens/NavScreen'));
const InboxScreen = lazy(() => import('./Screens/InboxScreen'));
const MapListScreen = lazy(() => import('./Screens/MapList'));
const SceneScreen = lazy(() => import('./Screens/SceneScreen'));

function App() {
  return (
    <ThemeProvider>
      <RemoveScroll>
        <Router>
          <AppContent />
        </Router>
      </RemoveScroll>
    </ThemeProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavBar = location.pathname !== '/auth';
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className={`App flex flex-col h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="content flex-grow flex flex-col">
        <Suspense fallback={<div className="flex items-center justify-center h-screen">Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/map" element={<MapScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/business" element={<BusinessScreen />} />
            <Route path="/auth" element={<AuthScreen />} />
            <Route path="/nav" element={<NavScreen />} />
            <Route path="/inbox" element={<InboxScreen />} />
            <Route path="/search-sheet" element={<MapListScreen />} />
            <Route path="/scene" element={<SceneScreen />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
